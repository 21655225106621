import { PostTypeEnum } from '../pages/create-post/create-post.model';
import { UserPaymentMethod } from './user-payment-method.model';

interface TimerLimits {
  postLimit: number;
  holdLimit?: number;
  releaseLimit?: number;
}

export interface QuantityLimit {
  min: number;
  max: number;
}

interface BaseCryptoPost {
  cryptoType: string;
  termsAndConditions: string;
  quantity: number;
  unitPrice: number;
  status: TradeOperationStatusEnum;
  timerLimits?: TimerLimits;
  quantityLimit?: QuantityLimit;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
}

export interface CryptoPost extends BaseCryptoPost {
  _id: string;
  username: string;
  userRating: number;
  userCompletionRate: number;
  userNumberOfOders: number;
  userAverageTime: number;
  date: string;
  acceptPaymentMethods: AcceptPaymentMethod[];
  idSellPost?: string;
  idBuyPost?: string;
  sellPostTransaction?: CryptoPost[];
  buyPostTransaction?: CryptoPost[];
  postType?: PostTypeEnum;
}

export interface GroupedCryptoPost {
  myPosts: CryptoPost[];
  generalPosts: CryptoPost[];
}

export interface CreateCryptoPostRequest extends BaseCryptoPost {
  buyPostPaymentMethods?: string[];
  sellPostUserPaymentMethods?: string[];
}

export interface GetPostsByMyTransactions {
  ownPost: { buyPost: CryptoPost[]; sellPost: CryptoPost[] };
  interactPost: { buyPost: CryptoPost[]; sellPosts: CryptoPost[] };
}

export interface AcceptPaymentMethod {
  paymentMethodId: string;
  userPaymentMethodId: string;
  bankName: string;
  accountType: string;
  accountNumber: string;
  customerName: string;
  customerIdentification: string;
  customerIdentificationType: string;
  customerPhone: string;
  paymentMethodName?: string;
}

export type TradePaymentMethod = AcceptPaymentMethod & UserPaymentMethod;

export enum TradeOperationStatusEnum {
  OPENED = 'OPENED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID_NOTIFIED = 'PAID_NOTIFIED',
  COMPLETED = 'COMPLETED',
  CONFLICTED = 'CONFLICTED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  EXPIRED_RELEASE = 'EXPIRED_RELEASE',
  EXPIRED_PAYMENT = 'EXPIRED_PAYMENT',
}
